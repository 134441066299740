.vclt_conatiner {
    padding: 60px 100px;
    box-sizing: border-box;
    /* background-color: gold; */
    text-align: center;
    width: 100%;
    float: left;
  }
  .vclt-heading {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 60px;
  }
  .vclt-col-wrap .vclt-column-box {
    width: 100%;
    float: left;
    text-align: left;
  }
  .vclt-col-wrap .vclt-column-box.header {
    padding: 10px;
    background: #1a2b57;
  }
  .vclt-col-1 h5 {
    color: #fff !important;
    font-size: 16px;
  }
  .vclt-col-2 h5 {
    color: #fff !important;
    font-size: 16px;
  }
  .vclt-col-1 {
    width: 18%;
    float: left;
  }
  .vclt-col-2 {
    width: 30%;
    float: left;
  }
  .vclt-col-wrap {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    max-height: 60vh;
    overflow: auto;
  }
  .vclt-column-box.border {
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
  }
  .vclt-col-1 .in-header-btn-box {
    text-align: left;
  }
  .vclt-column-box-in.border {
    display: flex;
    width: 100%;
    min-height: 50px;
    align-items: center;
    text-align: left;
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
  }
  .vclt-column-box-in.border p {
    /* overflow-wrap: break-word;
    word-wrap: break-word; */
    padding: 0px 5px;
}
.vclt-col-1.vclt-col-a {
  width: 10% !important;
}
.vclt-col-1.vclt-col-f {
  width: 26%;
}
.vclt-col-1.url-b p {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.ref-table-box.amrigin {
  /* height: 60vh;
  overflow: auto; */
  margin-top: 50px;
}

/* Mobile Responsive */

@media only screen and (max-width: 1040px) {
  .vclt-col-wrap {
    width: 960px;
  }
}