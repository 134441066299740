.ref-table-box {
    float: left;
    width: 100%;
    /* margin-bottom: 30px; */
}
.ref-table-in-box {
    width: 100%;
    float: left;
}
.ref-table-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.ref-inner-box {
    width: 100%;
    float: left;
    /* padding-top: 20px; */
    padding: 35px;
    padding-bottom: 30px;
}
.ref-top-section {
    width: 100%;
    float: left;
}

.ref-drowp {
    width: 50%;
    float: left;
}
.ref-btn-box {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 50px;
}
button.download {
    bottom: 0px;
    color: #fff;
    font-size: 18px;
    padding: 10px 24px !important;
    border-radius: 8px !important;
    border: solid 2px #F5843E;
    background: #F5843E !important;
    margin-left: 10px;
    cursor: pointer;
}
/* button.download:hover {
    background: rgba(107, 107, 107, 0.4) !important;
} */
.ref-top-section label.lddi_label {
    width: 100%;
    float: left;
    padding-left: 0px;
}
.ref-top-section .lddi_div1 {
    width: 100%;
    float: left;
    margin-left: 0px;
}
.ref-top-section .lddi_input_container {
    display: block;
}
.ref-table-head {
    width: 100%;
    float: left;
    background: #1a2b57;
        display: flex;
    /* flex-direction: row; */
    justify-content: space-around;
}
.ref-table-h {
    width: 20%;
    float: left;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 26px 20px;
}
.ref-table-body {
    width: 100%;
    border: solid 1px #ccc;
    justify-content: center;
    align-items: center;
    float: left;
    max-height: 60vh;
    overflow: auto;
}
.ref-table-text {
    width: 15%;
    min-height: 40px;
    
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-right: solid 1px #ccc;
    float: left;
    text-align: center;
    padding-top: 10px;
}
.ref-table-text.border {
    display: flex;
}
.ref-table-row {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-right: solid 1px #ccc;
    padding: 5px, 10px
}
a.table-link {
    color: #000 !important;
    text-decoration: none;
    padding-left: 10px;
    font-weight: bold;
}
.ref-table-text.border {
    border-right: none !important;
}
.ref-table-text.border button.send-btn {
    display: none;
}
.ref-table-text.border button.edit {
    background: transparent !important;
    box-shadow: none;
    border: 0px;
    color: #000 !important;
    text-decoration: none;
    padding-left: 10px;
    font-size: 18px;
    font-weight: bold;
}
.empty-reference-msge {
    /* for loading icon */
    display: flex;
    justify-content: center;
    /* for loading icon */
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
h3.error-ref-msge {
    font-size: 20px;
    font-weight: bold;
    color: #000 !important;
}
button.download a {
    text-decoration: none !important;
}
.ref-table-text {
    white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.ref-table-box2 {
    display: flex;
    justify-content: space-around;
    border-bottom: solid 1px #ccc;
}
/* .ref-table-box .upload-header-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
} */
.ref-table-box2:nth-child(even) {
    background: #F5F5F5;
}
.ref-table-box2:nth-last-child(1) {
    border-bottom: none !important;
 }