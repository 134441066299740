.alp_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: gold; */
  /* height: 100vh;
    width: 100vw; */
  padding: 0px 100px;
  flex-direction: column;
}
.alp_container .alp_inner_container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.alp_container .alp_inner_container .alp_btn_container {
  display: flex;
  margin-top: 30px;
}


/* my login style */
.login-inner-wrap {
  width: 50%;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  padding: 35px 30px;
  position: absolute;
  background: #fff;
  /* margin-top: -7%; */
  border-radius: 4px;
}
.login-inner-wrap .alp_inner_container {
  align-items: center !important;
}

.alp_container .alp_inner_container .alp_btn_container .alp_btn1 {
  background: #F5843E;
  border: solid 2px #F5843E;
  color: rgba(255,255,255,1);
  padding: 8px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
/* .alp_container .alp_inner_container .alp_btn_container .alp_btn1:hover {
  background: rgba(107, 107, 107, 0.4);
} */
.login-img {
  width: 100%;
  float: left;
  min-height: 350px;
}
.login-img img {
  width: 100%;
}
.alp_inner_container.login-screen {
  width: 100%;
  float: left;
}


.alp_inner_container.login-screen.title{
  margin-top: 0px;
}


.alp_inner_container.login-screen label.li_label {
  width: 30%;
  float: left;
  text-align: right;
}
.alp_inner_container.login-screen .li_container {
  width: 100%;
  float: left;
}
.alp_inner_container.login-screen .li_input_container {
  width: 50%;
  float: left;
}
.alp_inner_container.login-screen input.li_input {
  width: 100% !important;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  h1.alp_h1 {

    font-size: 28px;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  h1.alp_h1 {
    font-size: 28px;
}

.login-inner-wrap {
  width: 65%;
}

.login-inner-wrap{
  padding: 30px 0px;
}
  
}



@media (min-width: 992px) and (max-width: 1024px) {
  
  h1.alp_h1 {
    font-size: 28px;
}

.login-inner-wrap {
  width: 65%;
}

.login-inner-wrap{
  padding: 30px 0px;
}
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
  h1.alp_h1 {
    font-size: 24px;
}

.login-inner-wrap {
  width: 70%;
}

.login-inner-wrap{
  padding: 30px 0px;
}
  
}

@media (min-width: 576px) and (max-width: 767px) {
  .login-inner-wrap{
    position: unset;
    padding: 20px 0px;
    width: 100%;
  }

  h1.alp_h1 {
    font-size: 22px;
}

.alp_container{
  padding: 0px 50px;
}


}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 575px) {

  .login-inner-wrap{
    position: unset;
    padding: 20px 0px;
    width: 100%;
  }

  h1.alp_h1 {
    font-size: 20px;
}

.alp_container{
  padding: 25px;
}

.alp_inner_container.login-screen .li_container{
  flex-direction: column;
}

.alp_inner_container.login-screen .li_input_container{
  width: 100%;
  margin-left: 0px;
}

.alp_inner_container.login-screen label.li_label{
  text-align: left;
  width: 100%;
  padding: 10px 0px;
}

.alp_container .alp_inner_container{
  margin-top: 30px;
}

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .login-inner-wrap{
    position: unset;
    padding: 20px 0px;
    width: 100%;
  }

  h1.alp_h1 {
    font-size: 18px;
}

.alp_container{
  padding: 15px;
}

.alp_inner_container.login-screen .li_container{
  flex-direction: column;
}

.alp_inner_container.login-screen .li_input_container{
  width: 100%;
  margin-left: 0px;
}

.alp_inner_container.login-screen label.li_label{
  text-align: left;
  width: 100%;
  padding: 10px 0px;
}

.alp_container .alp_inner_container{
  margin-top: 25px;
}
  
}

@media (max-width: 319px) {
  .login-inner-wrap{
    position: unset;
    padding: 20px 0px;
    width: 100%;
  }

  h1.alp_h1 {
    font-size: 16px;
}

.alp_container{
  padding: 15px;
}

/* .alp_inner_container.login-screen label.li_label {
  font-size: 12px;
} */

.alp_inner_container.login-screen .li_container{
  flex-direction: column;
}

.alp_inner_container.login-screen .li_input_container{
  width: 100%;
  margin-left: 0px;
}

.alp_inner_container.login-screen label.li_label{
  text-align: left;
  width: 100%;
  padding: 10px 0px;
  font-size: 12px;
}

.alp_container .alp_inner_container{
  margin-top: 20px;
}
}