.drop_down_input_container {
  position: relative;
  /* margin-top: 16px; */
  /* background-color: chartreuse; */
  /* width: 80%; */
  display: flex;
  align-items: center;
  /* padding: 10px; */
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
}

.drop_down_input_container .drop_down_input {
  position: relative;
  all: unset;
  width: 100%;
  /* border:1px solid black; */
  /* border-radius: 5px; */
  /* background-color: chartreuse; */
  padding: 10px 20px 10px 10px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap;
  /* background-color: chartreuse; */
  /* color: #757575; */
}
.drop_down_input_container .drop_down_input:disabled {
  background-color: #e4e4e4;
  border-radius: 5px;
  cursor: default;
}

.drop_down_input_container .drop_down_icon {
  position: absolute;
  right: 0;
  /* background-color: crimson; */
}

.drop_down_input_error_text {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}
