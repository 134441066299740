.lddi_input_container{
    display: flex;
}

.lddi_input_container .lddi_label{
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
/* .lddi_input_container .lddi_div1{
    margin-left: 20px;
} */
.lddi_input_container .lddi_div1 .lddi_container{
    position: relative;
    /* margin-top: 16px; */
    /* background-color: chartreuse; */
    width: 300px;
    display: flex;
    align-items: center;
    /* padding: 10px; */
    box-sizing: border-box;
    /* border:1px solid black; */
    /* border-radius: 5px; */
}
select.lddi_input {
    box-sizing: border-box;
    border: 1px solid #cccccc !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    outline: none;
    height: 48px;
    color: #666666;
    border-radius: 4px !important;
    vertical-align: middle;
   
    font-family: 'Poppins', sans-serif;
}
.lddi_input_container .lddi_div1 .lddi_container .lddi_input{
    position: relative;
    all: unset;
    width: 100%;
    border:1px solid solid #94a4b4 !important;
    /* border-radius: 5px; */
    /* background-color: chartreuse; */
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1;
    cursor: pointer;
    color: #666666;
}

.lddi_input_container .lddi_div1 .lddi_container .lddi_icon{
position: absolute;
right: 0;
/* background-color: crimson; */
}

.lddi_input_container .lddi_error_text{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
}