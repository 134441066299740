.adm_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: gold; */
  /* height: 100vh;
    width: 100vw; */
  padding: 60px 100px;
  flex-direction: column;
}
.adm_p1 {
  margin-top: 20px;
}
.adm_container .adm_btn_container {
  display: flex;
  margin-top: 60px;
}
button.adm_btn1 {
  background: #f6853f;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


button.adm_btn1::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
button.adm_btn1 ::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245,96,12,0.5);
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}



button.adm_btn1::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
button.adm_btn1 ::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245,96,12,0.5);
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}
/* .adm_container .adm_btn_container .adm_btn1:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
  padding: 10px 30px !important;
} */

a.adm_link1 {
  color: #3a3f45;
  font-weight: 600;
  font-size: 16px !important;
  text-decoration: none;
}
a.adm_link1:hover {
  color: #f6853f !important;
}
body a.admmin_link1:hover{
  color: #f6853f !important;
}

/* my css */
.admin-inner-container {
  width: 85%;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  padding: 40px 60px 40px 60px;
  position: absolute;
  z-index: 9;
  background: #fff;
  border-radius: 4px;
}
h1.adm_h1 {
  color: #232319;
}
.admin-inner-container .adm_btn_container {
  text-align: center;
  height: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-slider img {
  width: 100%;
}
.admin-loading .loading-b {
  text-align: right;
}
.main-section-box {
  width: 100%;
  float: left;
}
.main-section-box.margin {
  margin-top: 30px;
}
.main-col1 {
  width: 31%;
  float: left;
  /* border: solid 1px #ccc; */
  box-shadow: 1px 2px 6px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  border-radius: 16px;
}
.main-col1.margin-third {
  margin: 0px 3%;
}
.main-col1.margin {
  float: right;
}
h4.link-heading {
  float: left;
  width: 100%;
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: bold;
}
.link-bo {
  width: 100%;
  float: left;
  text-align: left;
  padding-left: 15px;
}
p.adm-p-link {
  width: 100%;
  float: left;
}
p.adm-p-link a {
  font-size: 16px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8) !important;
}
a.admmin_link1 {
  font-size: 16px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8) !important;
  text-decoration: none;
}
p.adm-p-link.margin {
  margin-bottom: 10px;
}
.adm_container p.adm_p1.margin {
  margin-top: 40px;
}
h1.adm_h1 {
  padding-bottom: 30PX;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #000000 !important;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-top: 3px;
  margin-right: 8px;
}
.col-box {
  width: 100%;
  float: left;
}
span.dot-star {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 10px;
  margin-top: -2px;
}
span.dot-star img {
  width: 100%;
}




button.btn-yes {
  background: #F5843E;
  border: solid 1px #F5843E;
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}
button.btn-close {
  background: transparent;
  border: solid 2px #F5843E;
  color: #F5843E;
  padding: 4px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
}

button.btn-clos {
  padding: 5px 20px;
  background: transparent;
  border: solid 1px #F5843E;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  background: white;
  color: #F5843E;
}

button.delete-btn {
  bottom: 0px;
  color: #F5843E !important;
  font-size: 18px;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  border: solid 2px #F5843E !important;
  background: transparent;
}
.del-pop-text {
  width: 100%;
  float: left;
  margin-top: 30px;
  margin-bottom: 70px;
   text-align: left;
}