
.vcl_div_container .custom_drop_down_input_container .custom_drop_down_input:disabled{
  margin-left: 1px;
}
.vcl_conatiner .vcl_div_container {
  /* padding: 60px 100px 0px 100px; */
  padding: 60px 20px;
  box-sizing: border-box;
  width: 100%;
  float: left;
}
.vcl_conatiner .vcl_div_container .vcl_div {
  float: left;
  margin-right: 20px;
}
.vcl_conatiner .vcl_div_container .vcl_div1 {
  width: 20%;
}
.vcl_conatiner .vcl_div_container .vcl_div2 {
  width: 14%;
  float: left;
}
.vcl_conatiner .vcl_div_container .vcl_div3 {
  width: 10%;
  float: left;
}
.vcl_conatiner .vcl_div_container .vcl_div4 {
  width: 10%;
  float: left;
}
.vcl_conatiner .vcl_div_container .vcl_div .vcl_p1 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #5f5f5f;
  /* font-weight: bold; */
}
.vcl_conatiner .vcl_div_container .vcl_div .vcl_p1 .vcl_sp1 {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000;
}

.vcl_btn1,
.vcl_btn2 {
  background: #F5843E;
  border: 2px solid #F5843E;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  float: left;
  margin-top: 24px;
}
/* .vcl_btn1:hover,
.vcl_btn2:hover {
  background: rgba(107, 107, 107, 0.4);
} */

.vcl_btn2 {
  padding: 10px;
  float: right;
  margin-top: 0;
  margin-right: 100px;
}

.vcl_btn2:hover {
  padding: 11px;
}
.erpc_header.changelog-head {
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 40px;
}
.download {
  float: right;
  padding-top: 24px;
}
.download .vcl_btn2 {
  padding: 5px 10px;
}
.vcl_div.vcl_div2 input.dapi_input.box-input-date {
  height: 40px !important;
  border: solid 1px #000;
}
.vcl_div.vcl_div2 vspan .box-date-picker span {
  height: 40px !important;
  border: solid 1px #000;
}
.vcl_div.vcl_div2 .box-date-picker span {
  height: 40px;
  border: solid 1px #000;
}
.vcl_div.vcl_div3.dss .custom_drop_down_input_container {
  height: 43px;
  margin-right: 3px;
}
.vclt-column-box-in:nth-child(even) {
  background: #F5F5F5;
}
.ref-table-text {
  border-right: 0px !important;
}
.vcl_div_container .vcl_div.vcl_div2 p.dapi_error_text {
  width: 100%;
  float: left;
}


@media (min-width: 768px) and (max-width: 991px) {
  
  .vcl_conatiner .vcl_div_container .vcl_div .vcl_p1{
    font-size: 11px !important;
  }
  
  
}



