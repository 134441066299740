.card-list {
    width: 100%;
    float: left;
}
.upload-card-container {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
}
.upload-card-box {
    width: 100%;
    float: left;
    height: 80vh;
    overflow: auto;
    padding-top: 40px;
}
h2.headin-curr {
    width: 100%;
    float: left;
    padding-bottom: 28px;
    padding-top: 60px;
}
.card-box {
    width: 22%;
    float: left;
    margin-left: 16px;
    padding: 10px;
    box-shadow: 1px 4px 5px #000;
    margin-bottom: 38px;
}
.card-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    
}
.card-img img {
    height: 140px;
    max-width: 100%;
}
.card-banner-heading {
    width: 100%;
    float: left;
    text-align: left;
    padding: 14px 0px;
    font-size: 16px;
    font-weight: bold;
}
.card-banner-heading p {
    margin-bottom: 5px;
}
.card-banner-text-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
}
.card-banertext-left {
    justify-content: flex-start;
    width: 50%;
}
.card-banertext-left.text-right {
    justify-content: flex-end !important;
    align-items: flex-end !important;
    text-align: right;
}
.card-headig {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}
.card-text {
    font-size: 16px;
    color: #000;
}
.card-button-2 {
    display: flex;
    flex-direction: row;
    /* margin-top:10px */
}
.card-button {
    width: 50%;
    float: left;
    justify-content: center;
    text-align: center;
    margin: 0px 5px;
}
.card-button a {
    width: 100%;
    float: left;
    text-align: center;
    border: solid 2px #F5843E;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    padding: 8px 0px !important;
    border-radius: 8px !important;
}
.card-button-center {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 16px;
}
.card-button-center a {
    bottom: 0px;
    color: #F5843E !important;
    font-size: 18px;
    border: 0px;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    border: solid 2px #F5843E;
}
.upload-card-wrap {
    width: 100%;
    float: left;
    padding-top: 36px;
}
/* .upload-manage-wrap2 a:hover {
    list-style: none;
    text-decoration: none;
    background: rgba(107, 107, 107, 0.4);
} */
.upload-manage-wrap2 a {
    cursor: pointer;
}
.card-check-box {
    position: relative;
    margin-top: -18px;
    margin-left: -19px;
    cursor: pointer;
}
.card-check-box span.material-icons {
    border: solid 1px #656565;
    color: #ccc;
    font-weight: bolder;
    border-radius: 50px;
    padding: 2px;
}
.card-check-box.active span.material-icons {
    background: #656565;
    color: #fff;
}


/* Modal Delete */
.onclose-btn{
    position: 'absolute';
    top: '0';
    bottom: '0';
    left: '0';
    right: '0';
    display: 'grid';
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
            
}
.del-pop-text {
    width: 100%;
    float: left;
    margin-top: 30px;
    margin-bottom: 70px;
     text-align: left;
}
.del-pop-bnt {
    width: 100%;
    float: left;
    text-align: center;
}
button.btn-yes {
    background: #F5843E;
    border: solid 1px #F5843E;
    color: #fff;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
}
button.btn-close {
    background: transparent;
    border: solid 2px #F5843E;
    color: #F5843E;
    padding: 4px 20px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    margin-left: 10px;
    cursor: pointer;
}
button.delete-btn {
    bottom: 0px;
    color: #F5843E !important;
    font-size: 18px;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    border: solid 2px #F5843E !important;
    background: transparent;
}
/* button.delete-btn:hover {
    color: #fff !important;
    background: rgba(107, 107, 107, 0.4);
} */

/* Retailer Card */


.retailer-b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 140px;
    margin: 0px auto; */
}
.retailer-b img {
    height: 100px;
    max-width: 100%;
}
.retailer-card-box {
    width: 22%;
    float: left;
    margin-left: 16px;
    padding: 10px;
    margin-bottom: 38px;
}
.retailer-card {
    width: 100%;
    float: left;
    text-align: center;
}
.retailer-card.card-desi {
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 4px;
}
.image-text-tt {
    width: 100%;
    float: left;
    margin-bottom: 10px;
}
.image-text {
    width: 100%;
    float: left;
    padding-top: 12px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

/* Rder Card Design */

.rider-card {
    width: 100%;
    float: left;
    padding-top: 30px;
}
.rider-box {
    width: 100%;
    float: left;
}
.rider-card-img {
    width: 70px;
    float: left;
}
.rider-card-img img {
    width: 54px;
    border-radius: 50px;
    height: 54px;
}
.rider-main-text-b {
    float: left;
    width: 93%;
}
.title-rider {
    display: flex;
    padding-bottom: 15px;
}
h5.rider-name {
    font-size: 22px;
    font-weight: bold;
}
.date-question {
    padding-top: 4px;
    font-size: 18px;
    padding-left: 20px;
}
.box-rider-text {
    width: 100%;
    float: left;
    font-size: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.text-box {
    width: 66%;
    float: left;
}
.box-text {
    width: 100%;
    float: left;
    padding-top: 25px;
}
.box-text label.ltai_label {
    display: none;
}
.box-text .ltai_input_container {
    margin-left: 0px;
    width: 100%;
    border-radius: 4px;
}
.box-text textarea.ltai_input {
    width: 100% !important;
    border-radius: 4px;
}
.rider-ans-btn-box {
    width: 100%;
    float: left;
    text-align: right;
    padding-top: 15px;
}
button.send-btn {
    bottom: 0px;
    color: #fff;
    font-size: 18px;
    border: solid 2px #F5843E !important;
    padding: 10px 24px !important;
    border-radius: 8px !important;
    background: #F5843E !important;
    margin-left: 10px;
}
/* button.send-btn:hover {
    background: rgba(107, 107, 107, 0.4) !important;
} */
.rider-ans-button {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #707070;
    padding-top: 25px;
}
button.ans {
    font-size: 14px;
    color: #F5843E;
    background: #fff !important;
    box-shadow: none !important;
    border: 0px;
    cursor: pointer;
    border: solid 2px #F5843E;
    padding: 8px 18px;
    border-radius: 8px;
    font-weight: bold;
}
.btn-1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.del-pop-text.question {
    text-align: center;
}
button.send-btn {
    cursor: pointer;
}
.del-pop-text.ref-model {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
    padding-bottom: 60px;
    padding-top: 30px;
    font-weight: bold;
}
.ref-input-b {
    width: 100%;
    float: left;
}
.ref-input {
    width: 100%;
    display: flex;
    padding-bottom: 30px;
}
.ref-input label.li_label {
    width: 40%;
    float: left;
    text-align: right;
}
.ref-input .li_input_container {
    width: 50% !important;
    float: left;
}
.ref-input .li_input_container input.li_input {
    width: 100% !important;
}
.ref-input .li_container {
    width: 100%;
}
button.btn-yes.bo {
    float: left;
}
button.btn-yes.right {
    float: right;
}
.del-pop-bnt.refpadd {
    padding-top: 30px;
    padding-bottom: 30px;
}
.ref-btn-box button.edit {
    display: none;
}
button.delete-btn:active {
    border: solid 2px #F5843E !important;
}
button.card-icon {
    background: transparent;
    border: 0px;
}
.card-button-2.icon-m i {
    color: #F5843E;
    font-size: 22px;
    margin: 10px;
}
.card-button-2.icon-m {
    align-items: center;
    justify-content: center;
}
.card-button-2.icon-m span.material-icons {
    color: #F5843E;
    font-size: 26px;
    margin: 4px;
}
.bbb-box {
    width: 100%;
    float: left;
}
.card-box121 {
    padding-bottom: 40px;
}
.card-button-2.icon-m {
    width: 100px;
    margin: -40px auto 0px auto;
    z-index: 1;
    position: relative;
}