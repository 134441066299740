.admin-inner-container-am {
  /* width: 85%; */
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  /* padding: 40px 60px 40px 60px; */
  padding: 30px 50px 50px;
  position: absolute;
  z-index: 9;
  background: #fff;
  border-radius: 4px;
}

.adm_container-am {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1.adm_h1-am {
  color: #262626;
  font-size: 22px;
  text-align: left;
  margin-bottom: 0px;
  margin-left: 14px;
}

.brand-icon-am {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.main-col1-am {
  width: 100%;
  /* width: 31%; */
  float: left;
  /* border: solid 1px #ccc; */
  /* box-shadow: 1px 2px 6px #000; */
  background: #f4f4f4;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 210px;
  border-radius: 4px;
}

h4.link-heading-am {
  float: left;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 20px;
  text-align: left;
  font-size: 20px;
  /* font-weight: bold; */
  padding-left: 25px;
}

.link-bo.am {
  padding-left: 25px;
}

a.adm_link1.amt {
    font-weight: inherit;
}

a.admmin_link1.amt {
    font-weight: normal;
}

p.adm-p-link.margin.am {
    margin-bottom: 16px;
}


@media (min-width: 1025px) and (max-width: 1280px) {
  .admin-inner-container-am{
    width: 85%;
  }
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 992px) and (max-width: 1024px) {
  
  .admin-inner-container-am{
    width: 85%;
  }
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .admin-inner-container-am{
    width: 85%;
    position: unset;
    margin-top: -100px;
    padding: 30px 20px 50px;
  }
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}


/* @media (min-width: 768px) and (max-width: 991px) {
  
  .admin-inner-container-am{
    width: 85%;
  }
  
} */

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 576px) and (max-width: 767px) {
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
    /* padding: 30px 30px 50px; */
    margin: 25px;

  }

  .main-col1-am.margin-third {
    margin-top: 20px;
}

  
}

@media (min-width: 481px) and (max-width: 575px) {
  
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
  }

  .adm_container-am{
    padding: 20px;
  }

  .main-col1-am.margin-third {
    margin-top: 20px;
}
  
}



@media (min-width: 320px) and (max-width: 480px) {
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
    padding: 20px 15px;
  }

  .in-header-wrap.ihb {
    margin-top: 70px !important;
  }

  .adm_link1.amt{
    font-size: 12px;
  }

  h4.link-heading-am{
    font-size: 18px;
    padding-left: 15px;
  }

  .link-bo.am{
    padding-left: 15px;
  }

  .adm_container-am{
    padding: 20px;
  }

  .main-col1-am.margin-third {
    margin-top: 20px;
}

a.adm_link1.amt{
  font-size: 14px !important;
}

a.admmin_link1.amt{
  font-size: 14px !important;
}
}


@media (max-width: 319px) {
  
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
    padding: 20px 15px;

  }

  .in-header-wrap.ihb {
    margin-top: 60px !important;
  }

  .adm_link1.amt{
    font-size: 10px;
  }

  h4.link-heading-am{
    font-size: 14px;
    padding-left: 15px;
  }

  h1.adm_h1-am {
  font-size: 16px;
  } 

  .adm_container-am{
    padding: 15px;
  }

  .main-col1-am.margin-third {
    margin-top: 20px;
}
a.adm_link1.amt{
  font-size: 14px !important;
}

a.admmin_link1.amt{
  font-size: 14px !important;
}

.link-bo.am{
  padding-left: 15px;
}
}
