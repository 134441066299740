.ranges-text {
    display: flex;
    /* background-color: gold; */
    justify-content: space-between;
    margin-bottom: 30px;
}
.ranges-text .ranges-text-p1{
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
}
span.ranges-edit.right {
    float: right;
}
.ranges-text .ranges_btn1{
    background: #F5843E;
    border: solid 2px #F5843E;
    color: rgba(255,255,255,1);
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
  }
  /* .ranges-text .ranges_btn1:hover {
    background: rgba(107, 107, 107, 0.4);
  } */
.ranges-main-box .bp_list {
    border: 1px solid #cccccc;
    width: 560px;
    /* padding: 10px; */
    margin-left: 10px;
    height: 140px;
    overflow: auto;
}
.head-listrange {
    background: #1a2b57;
    padding: 5px 10px;
    margin-bottom: 5px;
}
.head-listrange h5 {
    color: #fff !important;
}
.ranges-mainbox {
    padding: 5px 10px;
    /* background-color: gold; */
}
span.ranges-edit {
    float: left;
    color: #3a3f45;
    text-decoration: underline;
    cursor: pointer;
    width: 50%;
    text-align: center;
    text-decoration: none;
}
span.ranges-edit:focus {
    
    color: #F5843E;
}
/* span.ranges-edit:hover{
    color:#f6853f
} */
.ranges-main-box p.bp_p2 {
    /* margin-bottom: 5px; */
    width: 80%;
    color: #666666;
}
.ranges-mainbox {
    display: flex;
}
.ranges-btn-edit {
    width: 20%;
    float: left;
}
