.erpc_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  flex-direction: column;
}
.erpc_container .erpc_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}
.erpc_container .erpc_header .erpc_btn0 {
  all: unset;
  position: absolute;
  right: 0;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  border: 1px solid #1a2b57;
  color: #1a2b57;
  cursor: pointer;
  box-sizing: border-box;
}
.erpc_content_container {
  margin-top: 40px;
}

.erpc_content_container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
}

.erpc_content_container td,
.erpc_content_container th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  /* padding: 8px; */

  /* text-align: right; */
  color: #666666;
}

.erpc_content_container tr th {
  padding: 20px;
}
.erpc_content_container tr th:nth-child(2) {
  padding-right: 20px;
}
.erpc_content_container tr th:nth-child(3) {
  padding: 20px 0px;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
  } */

.erpc_content_container tr td:nth-child(1) {
  padding: 20px;
  /* font-size: 14px;
      font-weight: 600; */
}
.erpc_content_container tr td:nth-child(2) {
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
.erpc_content_container tr .erpc-del-icon {
  float: left;
  color: #F5843E;
  cursor: pointer;
}

.erpc_content_container .erpc_btn1,
.erpc_content_container .erpc_btn2 {
  all: unset;
  height: 100%;
  /* width: 100%; */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 14px 0;
  border: 1px solid #1a2b57;
  color: #1a2b57;
  cursor: pointer;
  box-sizing: border-box;
}

.erpc_content_container .erpc_btn1 {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.erpc_content_container .erpc_link {
  text-decoration: none;
}
tr.erpc_btn1-box12 button.erpc_btn1 {
  width: 150px;
}

.erpc_container .erpc_header .erpc_btn0,
.erpc_content_container .erpc_btn1,
.erpc_content_container .erpc_btn2 {
  background: #F5843E;
  border: solid 2px #F5843E;
  color: #ffffff !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

/* .erpc_container .erpc_header .erpc_btn0:hover,
.erpc_content_container .erpc_btn1:hover,
.erpc_content_container .erpc_btn2:hover {
  background: rgba(107, 107, 107, 0.4);
} */

button.erpc_btn2.erpc_delete {
  margin-right: 10px;
}
td.erpc_btn1-box121 {
  text-align: right;
  padding-right: 33px;
}
td.button-right {
  text-align: right;
  padding-right: 36px;
}
div#exchange_rate input#si_input {
  width: 66%;
}