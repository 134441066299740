div#multiselectContainerReact {
    float: left;
}
._7ahQImyV4dj0EpcNOjnwA {
    background: #BEBEBE !important;
    font-size: 14px !important;
}
.li_container.select-dro-mult .li_container .li_input_container .li_error_text{
margin-top: 5px;
font-size: 12px;
color: red;
text-align: left;
}
.li_container.select-dro-mult .li_input_container {
width: 300px;
}
.li_container.select-dro-mult  input#search_input {
box-sizing: border-box;
/* border: 1px solid #cccccc; */
display: inline-block;
font-size: 14px;
font-weight: 400;
line-height: 18px;
outline: none;
height: 30px;
color: #666666;
border-radius: 4px;
padding: 0px;
}
.lhyQmCtWOINviMz0WG_gr { 
background: #F1F1F1 !important;
color: #000000 !important;
}
._3vt7_Mh4hRCFbp__dFqBCI li:hover{
background: #F1F1F1 !important;
color: #000000 !important;
}
li.false.option {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.pass-change-box li.false.option input {
    width: initial !important;
}
._3vLmCG3bB3CM2hhAiQX1tN{
    z-index: 999999 !important;
}
div#multiselectContainerReact input#search_input {
    width: initial !important;
}