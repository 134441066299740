/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */


/* footer pagination css */

.pagination-box {
  width: 100%;
  float: left;
  padding-bottom: 30px;
}
.pagination-inner-box {
  width: 100%;
  float: left;
  text-align: center;
}
.box-pagination {
  width: 100%;
  float: left;
}
.box-pagination .next-btn {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cont1 a {
  background: #F5843E;
  color: #fff;
  text-decoration: none !important;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;
}
.cont1 {
  width: 110px;
}
.count-btn a {
  color: #000;
  text-decoration: none !important;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;
}
a.active-page {
  background: #F5843E;
  color: #fff;
}

/* footer pagination css */


.outer-loader {
  position: relative;
}

.inner-loader {
  position: absolute;
  color: #F5843E;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./Assets/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(./Assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(./Assets/fonts/MaterialIcons-Regular.woff) format('woff'),
    url(./Assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/******************************************************************/
/******************************************************************/
/*********************** TransitPerx fonts ************************/
/******************************************************************/
/******************************************************************/
@font-face {
  font-family: 'inter-black';
  src: url('./Assets/fonts/inter-black-slnt-0.eot');
  src: url('./Assets/fonts/inter-black-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-black-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-black-slnt-0.svg#inter-black') format('svg');
}
@font-face {
  font-family: 'inter-bold';
  src: url('./Assets/fonts/inter-bold-slnt-0.eot');
  src: url('./Assets/fonts/inter-bold-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-bold-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-bold-slnt-0.svg#inter-bold') format('svg');
}
@font-face {
  font-family: 'inter-extrabold';
  src: url('./Assets/fonts/inter-extrabold-slnt-0.eot');
  src: url('./Assets/fonts/inter-extrabold-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-extrabold-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-extrabold-slnt-0.svg#inter-extrabold')
      format('svg');
}
@font-face {
  font-family: 'inter-extralight';
  src: url('./Assets/fonts/inter-extralight-slnt-0.eot');
  src: url('./Assets/fonts/inter-extralight-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-extralight-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-extralight-slnt-0.svg#inter-extralight')
      format('svg');
}
@font-face {
  font-family: 'inter-medium';
  src: url('./Assets/fonts/inter-medium-slnt-0.eot');
  src: url('./Assets/fonts/inter-medium-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-medium-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-medium-slnt-0.svg#inter-medium') format('svg');
}
@font-face {
  font-family: 'inter-regular';
  src: url('./Assets/fonts/inter-regular-slnt-0.eot');
  src: url('./Assets/fonts/inter-regular-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-regular-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-regular-slnt-0.svg#inter-regular') format('svg');
}
@font-face {
  font-family: 'inter-semibold';
  src: url('./Assets/fonts/inter-semibold-slnt-0.eot');
  src: url('./Assets/fonts/inter-semibold-slnt-0.woff') format('woff'),
    url('./Assets/fonts/inter-semibold-slnt-0.ttf') format('truetype'),
    url('./Assets/fonts/inter-semibold-slnt-0.svg#inter-semibold') format('svg');
}
@font-face {
  font-family: 'Oswald-Medium';
  src: url('./Assets/fonts/Oswald-Medium.eot');
  src: url('./Assets/fonts/Oswald-Medium.woff') format('woff'),
    url('./Assets/fonts/Oswald-Medium.ttf') format('truetype'),
    url('./Assets/fonts/Oswald-Medium.svg#Oswald-Medium') format('svg');
}

body {
  font-family: arial, sans-serif;
}
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.new-card-preview {
  width: 100%;
  float: left;
  padding: 8px 15px 20px 15px;
  border: solid 1px #d6d6d6;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 12%);
  margin-bottom: 30px;
}
.new-card-section {
  width: 100%;
  float: left;
  /* min-height: 450px; */
  /* min-height: 550px; */
}
.new-frist-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: center; */
  /* align-items: center; */
}
.new-discount {
  width: 50%;
  float: left;
  text-align: left;
}
.new-discount span {
  padding: 6px 12px;
  background: rgba(245, 132, 61, 0.12);
  font-size: 13px;
  color: #e8742d;
  line-height: 39px;
  font-family: 'inter-semibold';
  border-radius: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.new-ptr-price {
  float: right;
  width: 50%;
  text-align: right;
}
.new-ptr-price-box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  font-size: 16px;
  color: #bc229f !important;
  font-family: 'inter-semibold';
  padding: 6px 0px;
}
.new-ptr-price i.material-icons {
  font-size: 18px;
  float: left;
  padding-bottom: 1px;
}
.new-preview-coup-image {
  width: 100%;
  float: left;
  text-align: center;
}
.new-preview-img-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.new-preview-img-center img {
  max-height: 120px;
}
.new-previw-descripe-box {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 16px;
}
.new-previw-descripe-box {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 16px;
}
h4.new-descripe-heading {
  font-size: 16px;
  line-height: 25px;
  color: #1f1f1f;
  font-family: 'inter-semibold' !important;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}
.new-descripe-paragraph {
  font-size: 14px;
  line-height: 23px;
  color: #7b7b7b;
  font-family: 'inter-medium';
  width: 100%;
  float: left;
}
h1.new-preview-off-heading {
  margin: 8px 0px;
  width: 100%;
  float: left;
  font-size: 44px;
  line-height: 52px;
  font-family: 'Oswald-Medium';
  color: #bc229f !important;
}
.new-preview-date {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 8px;
}
.new-exp-date {
  width: 100%;
  float: left;
  font-size: 16px;
  line-height: 27px;
  font-family: 'inter-regular';
  color: #1f1f1f !important;
}
.new-exp-date b {
  font-family: 'inter-medium' !important;
}
.new-website-link-box {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 16px;
}
.new-website-link {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.new-website-link span.material-icons {
  float: left;
  font-size: 18px;
  margin-top: 2px;
}

.new-website-link a {
  color: #4989fe !important;
  font-size: 15px;
  line-height: 22px;
  font-family: 'inter-regular';
  text-decoration: none;
}
.new-website-link.bold a {
  font-family: 'inter-semibold' !important;
}
.new-website-link.bold {
  margin-bottom: 5px;
}
.new-url-map {
  float: left;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.new-descripe-paragraph-two {
  font-size: 15px;
  line-height: 24px;
  color: #404040 !important;
  width: 100%;
  float: left;
  font-family: 'inter-regular';
}
.new-descripe-paragraph-three {
  font-size: 16px;
  line-height: 28px;
  color: #575757;
  width: 100%;
  float: left;
  font-family: 'inter-semibold' !important;
  margin-top: 10px;
}
.new-exp-date-two {
  font-size: 15px;
  line-height: 32px;
  font-family: 'inter-medium';
  padding: 4px 20px;
  color: #43003e;
  background: #e1e8f2;
}
