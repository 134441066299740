body{
    font-family: Arial, Helvetica, sans-serif;
}
h1, h2, h3, h4, h5, h6{
    color: #000000 !important;
}
.main-header {
    background: #1a2b57;
    width: 100%;
    float: left;
    padding:  14px;
    position: fixed;
    z-index: 999;
}
.adm-container-header {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
button:focus {
    outline: 0px !important;
}
.header-log {
    width: 50%;
    float: left;
}
.header-in-b1 {
    display: flex;
    align-items: center;
}
