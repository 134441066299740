.vclt_conatiner {
    padding: 60px 20px !important;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    float: left;
  }
  .vclt_conatiner.padd-handle {
    padding-top: 0px !important;
}
  .vclt-heading {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 60px;
  }
  .vclt-col-wrap .vclt-column-box {
    width: 100%;
    float: left;
    text-align: left;
  }
  .vclt-col-wrap .vclt-column-box.header {
    padding: 10px;
    background: #1a2b57;
  }
  .vclt-col-1 h5 {
    color: #fff !important;
    font-size: 16px;
  }

  .icon-vclt {
    display: flex;
    justify-content: center;
}

  .vclt-col-2 h5 {
    color: #fff !important;
    font-size: 16px;
  }
  .vclt-col-1 {
    width: 16% !important;
    float: left;
  }
  .vclt-col-2 {
    width: 30%;
    float: left;
  }
  .vcl_conatiner .vclt-col-wrap {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    max-height: none !important;
    overflow: auto !important;
    
  }
  .scroll-vclt{
    width: 100%;
    float: left;
    max-height: 60vh;
    overflow: auto;
  }
  .vclt-column-box.border {
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
  }
  .vclt-col-1 .in-header-btn-box {
    text-align: left;
  }
  .vclt-column-box-in.border {
    display: flex;
    width: 100%;
    min-height: 50px;
    align-items: center;
    text-align: left;
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
  }
  .vclt-column-box-in.border p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 0px 8px !important;
}
.vclt-col-1.vclt-col-a {
  width: 10% !important;
}
.vclt-col-1.vclt-col-f {
  width: 18% !important;
}
.vclt-col-1.url-b p {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.vclt-col-12 {
  width: 6%;
  float: left;
}
.vclt-col-12 h5 {
  color: #fff !important;
  font-size: 16px;
}
/* Mobile Responsive */

@media only screen and (max-width: 1040px) {
  .vclt-col-wrap {
    width: 960px;
  }
}