.li_container{
    display: flex;
    /* background-color: cadetblue; */
    /* align-items: center; */
}
.li_container .li_label{
    /* font-size: 18px; */
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.login-inner-wrap label.li_label {
    width: 125px;
}
.li_container .li_input_container{

    margin-left: 20px;
}
/* my login style */
.li_container .li_input_container .li_input {
    box-sizing: border-box;
    border: 1px solid #cccccc;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    outline: none;
    height: 48px;
    color: #646464;
    border-radius: 4px;
    padding: 10px;
    width: 300px;
  }
  .li_container .li_input_container .li_input:active {
    border: solid 1px #94a4b4;
  }
.li_container .li_input_container .li_error_text{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
}