.erdp_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  flex-direction: column;
}
.erdp_container .erdp_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}

.erdp_container .erdp_header .erdp_btn0 {
  all: unset;
  position: absolute !important;
  right: 0;
  /* height: 100%;
      width: 100%; */
  /* margin-top: 40px; */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  border: 1px solid #1a2b57;
  color: #1a2b57;
  cursor: pointer;
  box-sizing: border-box;
}

/* .erdp_container .erdp_header .erdp_btn0:hover {
  background-color: #014f99;
  color: #fff;
} */
.erdp_content_container {
  margin-top: 40px;
}

.erdp_content_container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
  color: #666666;
  font-weight: bold;
}

.erdp_content_container td,
.erdp_content_container th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  /* padding: 8px; */

  text-align: right;
}

.erdp_content_container tr th {
  padding: 14px;
}
.erdp_content_container tr th:nth-child(2) {
  padding-right: 20px;
}
.erdp_content_container tr th:nth-child(3) {
  padding-right: 0px;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
  } */

.erdp_content_container tr td:nth-child(1) {
  padding: 20px;
  font-size: 14px;
  font-weight: 600;
}
.erdp_content_container tr td:nth-child(2) {
  padding-right: 20px;
}
.erdp_content_container tr .erdp-del-icon {
  float: left;
  color: #F5843E;
  cursor: pointer;
}
.erdp_content_container .erdp_btn1,
.erdp_container .erdp_header .erdp_btn0 {
  background: #F5843E;
  border: solid 2px #F5843E;
  color: #ffffff !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
/* .erdp_content_container .erdp_btn1:hover,
.erdp_container .erdp_header .erdp_btn0:hover {
  background: rgba(107, 107, 107, 0.4);
  
} */

.erdp_content_container .erdp_link {
  text-decoration: none;
}
button.erdp_btn1.erdp_delete {
  margin-right: 10px;
}
.si-date-picker {
  float: none;
  align-self: flex-end !important;
  display: flex;
}
.si-date-picker input.dapi_input.box-input-date {
  float: unset;
  width: 80%;
}

.ranges-mainbox:nth-child(odd) {
  background: #F5F5F5;
}